<script lang="ts">
  import cx from 'classnames'
  import {themeColors} from 'src/partials/state'

  export let icon
  export let accent = false

  $: color = $themeColors[accent ? "accent" : "neutral-100"]
</script>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" class={cx("inline", $$props.class)}>
  {#if icon === "bolt"}
    <path stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" d="M12.6725 0.978028L1.6694 5.39306C1.23849 5.56596 1.02303 5.6524 0.955738 5.78244C0.897219 5.89553 0.897619 6.03092 0.956767 6.14968C1.02482 6.2863 1.24082 6.3937 1.67285 6.6085L7.45472 9.48326L3.71195 15.0219L14.7151 10.6069C15.1459 10.434 15.3614 10.3476 15.4287 10.2175C15.4872 10.1045 15.4868 9.96909 15.4277 9.85034C15.3596 9.71372 15.1436 9.60633 14.7115 9.39151L8.92968 6.51675L12.6725 0.978028Z" />
  {:else if icon === "heart"}
    <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M11.3958 0.786443C12.9493 1.22537 14.1583 2.44773 14.58 4.00598C14.9429 5.49836 14.4467 7.06881 13.2922 8.08172L8.14799 13.1622C7.87285 13.4319 7.43249 13.4319 7.15735 13.1622L1.97072 8.08172C0.816203 7.06881 0.319941 5.49836 0.682855 4.00598C1.10459 2.44773 2.31353 1.22537 3.86702 0.786443C5.17302 0.428476 6.57148 0.714991 7.63146 1.55772C8.69145 0.714991 10.0898 0.428476 11.3958 0.786443ZM7.63146 11.6692L12.3227 7.06985C13.0921 6.39474 13.4129 5.34213 13.1506 4.35269C12.85 3.2887 12.0201 2.45611 10.9571 2.15209C9.91177 1.87655 8.79988 2.20357 8.07017 3.00119C7.79503 3.27088 7.35466 3.27088 7.07952 3.00119C6.52652 2.41398 5.76298 2.07041 4.95674 2.04596C4.71721 2.04974 4.47925 2.08543 4.24915 2.15209C3.18613 2.45611 2.35623 3.2887 2.05563 4.35269C1.80674 5.35303 2.15006 6.40771 2.94012 7.06985L7.63146 11.6692Z" />
  {:else if icon === "message"}
    <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M2.22282 0.976503H12.596C13.8237 0.976503 14.8188 1.9717 14.8188 3.19932V10.6087C14.8188 11.8363 13.8237 12.8315 12.596 12.8315H11.855V15.0544C11.8535 15.3535 11.6723 15.6224 11.3956 15.736C11.3077 15.7775 11.2113 15.7978 11.1141 15.7953C10.9172 15.7964 10.7278 15.7191 10.588 15.5804L7.84649 12.8315H2.22282C0.995195 12.8315 0 11.8363 0 10.6087V3.19932C0 1.9717 0.995195 0.976503 2.22282 0.976503ZM12.596 11.3497C13.0052 11.3497 13.3369 11.0179 13.3369 10.6087V3.19932C13.3369 2.79011 13.0052 2.45838 12.596 2.45838H2.22282C1.81361 2.45838 1.48193 2.79011 1.48193 3.19932V10.6087C1.48193 11.0179 1.81361 11.3497 2.22282 11.3497H8.15039C8.34733 11.3485 8.53655 11.4258 8.67638 11.5645L10.3732 13.2687V12.0906C10.3732 11.6814 10.7049 11.3497 11.1141 11.3497H12.596Z" />
  {/if}
</svg>
