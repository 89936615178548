<script lang="ts">
  import Input from "src/partials/Input.svelte"
  import ImageInput from "src/partials/ImageInput.svelte"
  import Textarea from "src/partials/Textarea.svelte"
  import Anchor from "src/partials/Anchor.svelte"
  import Heading from "src/partials/Heading.svelte"
  import Field from "src/partials/Field.svelte"
  import {pubkey, people, publishProfile} from "src/engine"
  import {router} from "src/app/router"
  import {toastProgress} from "src/app/state"

  const nip05Url = "https://github.com/nostr-protocol/nips/blob/master/05.md"
  const lud16Url = "https://blog.getalby.com/create-your-neutral-300ning-address/"

  const submit = async () => {
    const pub = await publishProfile(values)

    pub.on("progress", toastProgress)

    router.pop()
  }

  const values = {...people.key($pubkey).get()?.profile}

  document.title = "Profile"
</script>

<form on:submit|preventDefault={submit}>
  <div class="mb-4 flex flex-col items-center justify-center">
    <Heading>About You</Heading>
  </div>
  <div class="flex w-full flex-col gap-8">
    <Field label="Username">
      <Input type="text" name="name" wrapperClass="flex-grow" bind:value={values.display_name}>
        <i slot="before" class="fa-solid fa-user-astronaut" />
      </Input>
      <div slot="info">In most clients, this image will be shown on your profile page.</div>
    </Field>
    <Field label="NIP-05 Identifier">
      <Input type="text" name="name" wrapperClass="flex-grow" bind:value={values.nip05}>
        <i slot="before" class="fa-solid fa-user-check" />
      </Input>
      <div slot="info">
        Enter a <Anchor class="underline" external href={nip05Url}>NIP-05</Anchor> address to verify
        your public key.
      </div>
    </Field>
    <Field label="Lightning address">
      <Input type="text" name="name" wrapperClass="flex-grow" bind:value={values.lud16}>
        <i slot="before" class="fa-solid fa-bolt" />
      </Input>
      <div slot="info">
        Enter a <Anchor class="underline" external href={lud16Url}>LUD-16</Anchor> address to enable
        sending and receiving lightning tips (LUD-06 will also work).
      </div>
    </Field>
    <Field label="Website">
      <Input type="text" name="name" wrapperClass="flex-grow" bind:value={values.website}>
        <i slot="before" class="fa-solid fa-link" />
      </Input>
      <div slot="info">Enter any url where people can find out more about you.</div>
    </Field>
    <Field label="About you">
      <Textarea name="about" bind:value={values.about} />
      <div slot="info">Tell the world about yourself. This will be shown on your profile page.</div>
    </Field>
    <Field label="Profile Picture">
      <ImageInput
        bind:value={values.picture}
        icon="image-portrait"
        maxWidth={480}
        maxHeight={480} />
      <p slot="info">Please be mindful of others and only use small images.</p>
    </Field>
    <Field label="Profile Banner">
      <ImageInput bind:value={values.banner} icon="panorama" />
      <div slot="info">In most clients, this image will be shown on your profile page.</div>
    </Field>
    <Anchor button tag="button" type="submit">Save</Anchor>
  </div>
</form>
